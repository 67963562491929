.help {

    a {
        text-decoration: underline;
        font-weight: 500;
    }

    li {
        margin-bottom: 8px;
    }

    &.dashboard {
        .breadcrumb {
            max-width: unset;
        }

        .banner {
            min-height: unset;
            
            .banner-container {
                margin: unset;
                padding-bottom: 0;
            }
        }

        .help-content {
            max-width: unset;
        }
    }

    .breadcrumb {
        max-width: 1200px;
        margin: auto;
        padding: 8px 1rem 0;
        gap: 0;

        @media (max-width: 991px) {
            padding: 8px 1rem 0;
        }

        li {
            color: rgba(0, 0, 0, 0.60);
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            display: flex;
            gap: 6px;
            text-wrap: nowrap;

            &:last-child {
                overflow: hidden;
            }

            a {
                color: rgba(0, 0, 0, 0.40);
                text-decoration: underline;
            }
        }
    }

    .banner {
        background: #CEE6F9;
        min-height: 330px;
        margin-bottom: 40px;

        .banner-container {
            padding-top: 50px;
            padding-bottom: 60px;
        }

        h1 {
            margin-bottom: 1rem;
        }
    }

    .help-content {
        max-width: 1200px;
        margin: auto;
        margin-bottom: 50px;
        padding: 0 1rem;

        & > div {
            max-width: 600px;

            &:first-child {
                border-top: 0px !important;
                padding: 0 !important;
                margin: 0 !important;
            }

            h2, p {
                max-width: 570px;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                line-height: 32px;
                margin-top: 0;
                margin-bottom: 8px;
            }
            
            p {
                font-family: Lora;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px; /* 150% */
                letter-spacing: 0.2px;
                margin-top: 0;
                margin-bottom: 24px;
            }

            button {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
                border: 0;
                padding: 0;
                background: 0;
            }
        }
    }
}