.pricing {
    background: #cee6f9;

    .breadcrumb {
        max-width: 1200px;
        margin: auto;
        padding: 8px 1rem 0;
        gap: 0;

        @media (max-width: 991px) {
            padding: 8px 1rem 0;
        }

        li {
            color: rgba(0, 0, 0, 0.60);
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            display: flex;
            gap: 6px;

            a {
                color: rgba(0, 0, 0, 0.40);
                text-decoration: underline;
            }
        }
    }
    .banner {
        background-image: url("../../assets/img/pricing-banner.svg");
        background-position: center;
        min-height: 450px;
        background-color: #ffffff;

        @media (max-width: 991px) {
            background: #F9F9F9;
            background-image: url("../../assets/img/pricing-banner-mobile.svg");
            min-height: 440px;
            background-size: 100% 135%;
        }
    }

    .banner-container {
        padding-top: 50px;

        @media (max-width: 991px) {
            padding-top: 40px;
        }

        h1 {
            margin-bottom: 8px;
        }
    
        p {
            font-size: 16px;
            margin-bottom: 26px;
            max-width: 550px;
        }
    }

    .grapple-letters-list {
        background: #F9F9F9;
        background-image: unset;
        padding-top: 50px;
        padding-bottom: 150px;

        @media (max-width: 991px) {
            padding-top: 40px;
            padding-bottom: 15px;
        }

        .letter-item {
            border: 1px solid #CDCBC7;
        }
    }

    .pricing-table {
        max-width: 1024px;
        margin: auto;
        display: flex;
        gap: 30px;
        justify-content: center;
        margin-top: -200px;
        padding: 0 12px;
        margin-bottom: 100px;

        @media (max-width: 767px) {
            flex-direction: column;
            margin-top: -150px;
            margin-bottom: 40px;
        }

        .basic-plan {
            padding-bottom: 26px;
            border-bottom: 12px solid #4E70A6;
        }
    
        .premium-plan {
            padding-bottom: 26px;
            border-bottom: 12px solid #ECB344;
    
            .plan-header {
                background: #ECB344;
    
                h3, p {
                    color: #292929;
                    border-bottom: 0px;
                }
            }

            hr {
                margin-bottom: 16px;
                border-top: 1px solid transparent !important;
            }
        }
    
        .basic-plan, .premium-plan {
            border-radius: 9px;
            background: #FFF;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.11);
            max-width: 380px;

            @media (max-width: 991px) {
                max-width: unset;
                width: 100%;
            }
    
            .plan-header {
                border-radius: 9px 9px 0px 0px;
                padding: 17px 24px 0px;
            }

            .button {
                border-radius: 60px;
                border: 2px solid #3592FE;
                color:#3592FE;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
                padding: 14px 16px;
                width: 100%;
                display: block;
                text-align: center;
                margin-top: 47px;
                cursor: pointer;

                &.premium {
                    background: #ECB344;
                    border: 2px solid #ECB344;
                    color: #292929;
                }
            }
    
            .plan-body {
                padding: 15px 24px 0;
            }

            hr {
                border: 0;
                border-top: 1px solid #565457;
                margin-top: -8px;
            }
    
            h3 {
                font-size: 31px;
                font-weight: 700;
                line-height: 62px; /* 200% */
                margin: 0;

                span {
                    display: block;
                    font-weight: 500;
                    margin-top: -10px;
                }
            }
    
            p {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 0.2px;
                margin: 0;
                padding-bottom: 9px;
                border-bottom: 2px solid #f1f1f1;
            }

            ul.disabled {
                list-style: url("../../assets/img/Cancel-Icon.svg");
                margin-top: 16px;
                li {
                    opacity: 0.7;
                    color: #000204;
                }
            }
    
            ul {
                list-style: url("../../assets/img/dark-tick.svg");
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 1.5rem;
                margin-bottom: 28px;
                margin-top: 0;
    
                @media (max-width: 991px) {
                    margin-bottom: 48px;
                }
        
                li {
                    color: #292929;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.2px;
                    margin-bottom: 24px;
                    padding-left: 0px;

                    &.free{
                        opacity: 0.3;
                    }
    
                    b {
                        display: block;
                    }
                }
            }
        }
    }
}