.action-popup {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 13.2px 3px rgba(48, 48, 48, 0.07);
    max-width: 400px;
    position: absolute;
    top: 175px;
    right: 0;
    left: 0;
    margin: auto;
    max-height: max-content;
    text-align: center;
    z-index: 1;
    display: block;
    padding: 30px 30px 15px;

    @media (max-width: 991px) {
        max-width: 350px;
        padding: 26px 26px 13px;
        top: 145px;
    }

    &.upgrade {
        max-width: 550px;

        @media (max-width: 991px) {
            left: 16px;
            right: 16px;
        }

        .action-popup-footer {
            .button {
                width: max-content;
                min-width: 160px;
    
                @media (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }

    &:before {
        position: fixed;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -2;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.85);
    }

    img {
        margin-bottom: 34px;
    }

    h4 {
        margin-top: 0;
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 8px;
    }

    p {
        color: #000;
        text-align: center;
        font-family: Lora;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-bottom: 50px;
        margin-top: 0;

        @media (max-width: 991px) {
            margin-bottom: 34px;
        }
    }

    .action-popup-footer {
        display: flex;
        justify-content: space-between;

        @media (max-width: 991px) {
            flex-direction: column-reverse;
            gap: 1rem;
        }

        .button {
            border-radius: 60px;
            background: unset;
            border: 0;
            padding: 14px 15px;
            color: #000;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            width: 160px;

            @media (max-width: 991px) {
                width: 100%;
            }

            &.logout-button {
                color: #FFF;
                background: #3592FE;
            }
        }
    }

    &.update-letter {
        background: #FFF;
        max-width: 635px;
        padding-top: 82px;

        @media (max-width: 991px) {
            max-width: 350px;
            padding: 70px 18px 13px;
        }

        .action-popup-footer {
            .button {
                width: 270px;
                padding: 14px;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
}