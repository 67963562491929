.achievements {
    display: flex;
    max-width: 720px;
    margin: auto;
    justify-content: space-between;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 991px) {
        flex-direction: column;
        gap: 36px;
    }

    p {
        font-family: Lora;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin: 0;

        @media (max-width: 991px) {
            margin-bottom: 12px;
        }
    }

    a {
        margin-top: 5px;
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-decoration-line: underline;

        @media (max-width: 991px) {
            margin-top: 16px;
        }
    }
}