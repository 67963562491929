.payment-modal {
    color: #000;
    max-width: 100%;
    max-height: 100% !important;

    @media (min-width: 768px) {
        max-width: 400px;
        max-height: 90% !important;
    }
    .p-dialog-title {
        margin-bottom: -5px;
    }    
    .p-dialog-content{
        text-align: center;

        .header-text {
            font-size: 14px;
        }

        h4 {
            margin-top: 22px;
            margin-bottom: 10px;
        }

        ul {
            font-size: 14px;
            text-align: left;
            padding-top: 6px;
            padding-bottom: 6px;
        }

        p {
            margin: 0;
            color: #292929;
        }

        .bottom-text {
            font-size: 11px;
            font-weight: 500;
            margin: auto;
        }

        img {
            margin-top: 12px;
            margin-bottom: 22px;
            margin-left: auto;
            margin-right: auto;
        }

        .button-text {
            margin-bottom: 20px;
            font-weight: 500;
            text-decoration: underline;
            color: #3592FE;
            cursor: pointer;
        }

        .payment-button {
            width: max-content;
            text-decoration: unset;
            margin: auto;
            color: #fff;
            padding: 10px 20px;
            border-radius: 20px;
            background: #3592FE;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.20);
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 16px;
            border: unset;
            cursor: pointer;
        }

        #payment-element {
            margin: 20px 0 5px;
            padding: 0.75rem;
            border: 1px solid #e6e6e6;
            border-radius: 5px;
        }

        .payment-complete {
            h4 {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 10px;
            }
        }

        .payment-form {
            img {
                margin-bottom: 0.5rem;
            }

            h3, h4 {
                margin-top: 0;
                margin-bottom: 3px;
            }

            p {
                margin-top: 10px;
                font-size: 14px;
            }

            form {
                margin-top: 1rem;
            }

            button {
                color: #fff;
                padding: 10px 20px;
                border-radius: 20px;
                background: #3592FE;
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.20);
                margin-top: 20px;
                font-size: 16px;
                border: unset;
                width: 100%;
            }
            
        }

        div#payment-message {
            color: red;
            margin-top: 10px;
            font-weight: 500;
            font-size: 14px;
        }
        .listing {
            margin-top: 14px;
            margin-bottom: 30px;

            img {
                margin: 0 !important;
            }

            .listing-item {
                align-items: center;
                display: flex;
                gap: 10px;
                margin-bottom: 16px;
                margin-right: 10px;

                p {
                    margin-right: 20px;
                    font-size: 15px;
                    text-align: left;
                }
            }
        }
        .price-list {
            display: flex;
            margin-bottom: 12px;
            gap: 8px;
            margin-top: 45px;

            .price-list-item {
                position: relative;
                border: 1px solid #cfcfcf;
                border-radius: 9px;
                padding: 10px;
                width: 100%;
                border-bottom: 11px solid #cfcfcf;

                &.active {
                    border: 1px solid #3592FE;
                    border-bottom: 11px solid #3592FE;

                    .price-title {
                        background: #3592FE;
                        color: #fff;
                    }
                }
            }
            .price-title {
                font-size: 11px;
                font-weight: 600;
                line-height: 135%;
                letter-spacing: -0.33px;
                padding-top: 3px;
                padding-bottom: 3px;
                border-radius: 8px;
                background: #cfcfcf;
                position: absolute;
                top: -10px;
                width: 63px;
                left: 0;
                right: 0;
                margin: auto !important;
            }
            .price-duration {
                font-size: 15px;
                font-weight: 500;
                line-height: 135%;
                letter-spacing: -0.75px;
                margin-top: 18px !important;
                margin-bottom: 5px !important;
            }
            .price {
                font-size: 23px;
                font-weight: 700;
                line-height: 135%;
            }
        }
    }
} 

.spinner {
    border: 4px solid rgba(0,0,0,.1);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border-left-color: #09f;
    margin: auto;
  
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}