.banner {
    background-image: url("../../assets/img/banner.svg");
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 650px;

    @media (max-width: 991px) {
        background-image: url("../../assets/img/banner-mobile.svg");
        min-height: 610px;
    }
    
    .banner-container {
        max-width: 1200px;
        margin: auto;
        padding: 82px 1rem 78px 1rem;

        @media (max-width: 991px) {
            padding: 40px 1rem;
        }

        .banner-caption {
            max-width: 50%;

            @media (max-width: 991px) {
                max-width: 100%;
            }
        }

        h1 {
            font-size: 45px;
            font-weight: 500;
            line-height: 62px;
            margin-top: 0;
            margin-bottom: 42px;

            @media (max-width: 991px) {
                font-size: 32px;
                margin-bottom: 16px;
                line-height: 48px; /* 150% */
            }
        }
    
        p {
            font-family: Lora;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin-top: 0;
            margin-bottom: 42px;

            @media (max-width: 991px) {
                margin-bottom: 40px;
            }
        }

        .get-started {
            width: 400px;
            text-align: center;
            display: block;
            padding: 18px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 60px;
            background: #3576D9;
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
            cursor: pointer;

            @media (max-width: 991px) {
                width: 100%;
            }
        }
    
        ul {
            list-style: url("../../assets/img/blue-tick.svg");
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1.5rem;
            margin-bottom: 28px;
            margin-top: 0;

            @media (max-width: 991px) {
                margin-bottom: 48px;
            }
    
            li {
                color: #25334D;
                font-family: Lora;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                letter-spacing: 0.2px;
                margin-bottom: 16px;
                padding-left: 8px;
            }
        }
    }
}