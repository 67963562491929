.userLayout {
    display: flex;
    width: 100%;
    padding-right: 5.125rem;
    gap: 32px;
    background: #CEE6F9;

    @media (max-width: 991px) {
        padding-right: 0;
    }

    .sidebar {
        background: #4E70A6;
        padding-top: 50px;
        padding-bottom: 20px;

        @media (max-width: 991px) {
            display: none;
        }

        .menu-item {
            display: flex;
            width: 165px;
            padding: 8px 20px;
            align-items: flex-start;
            gap: 8px;
            background: #fff;
            margin-bottom: 2px;

            &.active {
                position: relative;
                
                &::after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    background: #4E70A6;
                    height: 100%;
                    top: 0px;
                    left: 5px;
                    bottom: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 6px;
                    background: #3592FE;
                    height: 100%;
                    top: 0px;
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }
    .content {
        width: 100%;
    }
}

.thank-you {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h2 {
        font-size: 28px;
        font-weight: 600;
    }
}