.grapple-letters-list {
    background-image: url(../../assets/img/letters-list.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    .grapple-letters-list-container {
        max-width: 1200px;
        margin: auto;
        padding: 64px 1rem;
    
        @media (max-width: 991px) {
            padding: 40px 1rem;
            background-color: #CEE6F9;
        }
    
        h2 {
            color: #000;
            text-align: center;
            font-size: 37px;
            font-style: normal;
            font-weight: 500;
            line-height: 47.1px;
            margin-top: 0;
            margin-bottom: 2rem;
    
            @media (max-width: 991px) {
                font-size: 28px;
                font-weight: 500;
                line-height: 52px; /* 185.714% */
                margin-bottom: 16px;
            }
        }
    
        & > div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
    
            @media (max-width: 991px) {
                grid-template-columns: 1fr;
                grid-gap: 1rem;
            }
        }
    
        .letter-item {
            border-radius: 8px;
            background: #FFF;
            padding: 10px;
            height: max-content;
            min-height: 104px;
    
            p {
                color: #292929;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                margin: 0;
                letter-spacing: 0.2px;
            }
    
            .letter-header {
                display: flex;
                justify-content: space-between;
            }
        
            .letter-title {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 10px;
        
                h5, h5 a {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px; /* 171.429% */
                    letter-spacing: 0.2px;
                    text-decoration-line: underline;
                    margin: 0;
                }
            }
        }
    
    }
}