.update-letter {
    padding-top: 40px;
    background: #CEE6F9;

    .user-answers {
        max-width: 540px;
        margin: auto;

        @media (max-width: 991px) {
            margin: 0 16px;
        }    

        h2 {
            color: #000;
            font-family: Montserrat;
            font-size: 22px;
            font-weight: 500;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 8px;
        }

        h4 {
            margin-top: 2rem;
            margin-bottom: 0;
            font-weight: 600;
        }

        p {
            color: #000;
            font-family: Lora;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin-top: 0;
            margin-bottom: 8px;
        }

        .answers-container {
            margin-top: 16px;
            border-radius: 8px;
            background: #FFF;
            padding: 16px;

            .answers {
                max-height: 300px;
                overflow: auto;

                p {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                }
    
    
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }
    
                &::-webkit-scrollbar {
                    width: 4px;
                    background-color: transparent;
                }
    
                &::-webkit-scrollbar-thumb {
                    background-color: #D6D6D6;
                }
            }

            .action {
                border-top: 1px solid #D6D6D6;
                margin-top: 16px;
                padding-top: 16px;
                display: flex;
                gap: 4px;
                justify-content: flex-end;
            }

        }
    }

    .action-buttons {
        background: #fff;
        padding: 24px 0;
        margin-top: 80px;

        @media (max-width: 991px) {
           background: unset;
           margin-top: 20px;
           margin-bottom: 40px;
        }
    
        > div {
            max-width: 540px;
            margin: auto;
            display: flex;
            justify-content: space-between;

            @media (max-width: 991px) {
                flex-direction: column;
                gap: 20px;
                margin: 0 16px;
            }        
        }

        .button {
            border-radius: 60px;
            border: 1px solid #3592FE;
            min-width: 160px;
            padding: 16px 14px;
            text-align: center;
            color: #000;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.2px;

            &.continue {
                min-width: 230px;
                background: #3592FE;
                color: #fff;
            }
        }
    }
}