.header {
    border-bottom: 1px solid rgba(231, 231, 231, 1);

    .header-component {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #ffffff;
        border: unset;
        padding: 0.75rem 1rem;

        .p-menubar-button {
            width: 2.125rem;
            height: 2.125rem;
            margin: 0.75rem;
            border-radius: 0;
            background-image: url('../../assets/img/hamburger.svg') !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;

            &:focus {
                box-shadow: unset;
            }

            &:hover {
                background: unset;
            }

            .p-icon {
                display: none;
            }
        }

        &.p-menubar-mobile-active {
            position: fixed;
            z-index: 1;
            
            .p-menubar-button {
                background-image: url('../../assets/img/Close.svg') !important;
            }

            &::before {
                position: fixed;
                content: "";
                top: 5.375rem;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.60);
                z-index: 1;
            }

            .p-menubar-root-list {
                width: 90%;
                max-width: 18.75rem;
                height: 100vh;
                box-shadow: unset;
                padding-top: 1rem;
                padding-bottom: 1rem;
                right: 0;
                left: unset;

                .menu-item-terms {

                    .p-menuitem-link {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.40);
                        margin-bottom: 2rem;
                    }
                }

                .p-menuitem {
                    margin: 0 !important;

                    &.active {
                        border-bottom: 3px solid #3576D9;

                        @media screen and (max-width: 991px) {
                            border-bottom: 0px solid #3576D9;
                        }
                    }
                }

                .button {
                    width: 15.625rem !important;
                    margin: 0 !important;
                    height: 2.5rem;
                    margin-bottom: 1.125rem !important;

                    .p-menuitem-link {
                        font-size: 1rem;
                        padding: 0;
                        margin: auto;
                        border: 0;
                    }
                }

                .p-menuitem-link {
                    font-size: 1.125rem;
                    padding: 1.5rem 0.875rem !important;
                    border-top: 1px solid rgba(0, 0, 0, 0.40);

                }
            }
        }

    }
    .header-logo {
        cursor: pointer;
        width: 125px;
        height: auto;
    }

    .p-menubar .p-menubar-root-list {

        .p-menuitem {
            margin-right: 1.5rem;

            &.active {
                position: relative;
                &::after {
                    content: "";
                    background: #3576D9;
                    height: 3px;
                    position: absolute;
                    left: 0;
                    right: 0;

                    @media screen and (max-width: 991px) {
                        right: unset;
                        top: 0;
                        width: 3px;
                        height: 100%;
                    }
                }
            }

            .p-menuitem-link {
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: 0.2px;
                padding: 0;
                padding-right: 0;

                .p-menuitem-text {
                    color: #000;
                }
            }

            &.button {
                border: 1px solid #3576D9 !important;
                border-radius: 0.5rem;
                text-align: center;
                display: flex;
                margin-right: 1.125rem;

                &.active {
                    position: relative;

                    &::after {
                        display: none;
                    }
                }

                .p-menuitem-link {
                    padding: 0 !important;
                    margin: auto;
                    width: 7.5rem;
                    height: 2.5rem;

                    span {
                        margin: auto;
                    }
                }
                &.menu-item-premium {
                    background: #ECB344;
                    border: 1px solid #ECB344 !important;
                }
                &.menu-item-signup {
                    margin-left: 0.375rem;
                    background: #3576D9;

                    .p-menuitem-link {

                        .p-menuitem-text {
                            color: #fff;
                        }
                    }
                }
                &.menu-item-login {
                    margin-right: 0;
                }
            }
        } 

    } 
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
        background: unset;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        box-shadow: unset;
    }
}

@media (min-width: 64rem) {
    .header {
        .header-component {
            padding: 0.75rem 5.125rem 0.75rem 5.125rem;
        }
    }
}  

.breadcrumb {
    padding: 12px 0;
    margin: 0;
    list-style: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    gap: 4px;

    @media screen and (max-width: 991px) {
        padding: 12px 16px;
    }

    li {
        padding: 0;
        margin: 0;
    }
}

.faqs-page {
    .faqs {
        background: #fff;
        padding: 50px 20px;
        margin-bottom: 20px;

        .faqs-inner {
            max-width: 900px;
            margin: auto;
        }
    }
    h2 {
        margin: 0;
        margin-bottom: 10px;
    }
}