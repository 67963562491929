.grapple-letters {

    .breadcrumb {
        max-width: 1200px;
        margin: auto;
        padding: 8px 1rem 0;
        gap: 0;

        @media (max-width: 991px) {
            padding: 8px 1rem 0;
        }

        li {
            color: rgba(0, 0, 0, 0.60);
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            display: flex;
            gap: 6px;

            a {
                color: rgba(0, 0, 0, 0.40);
                text-decoration: underline;
            }
        }
    }
    .banner {
        background-image: url("../../assets/img/grapple-letters-banner.svg");
        background-position: center;
        min-height: 438px;

        @media (max-width: 991px) {
            background-image: url("../../assets/img/grapple-letters-banner-mobile.svg");
            min-height: 515px;
        }
    }

    .banner-container {
        padding-top: 50px;

        @media (max-width: 991px) {
            padding-top: 40px;
        }

        h1 {
            margin-bottom: 8px;
        }
    
        p {
            margin-bottom: 26px;
        }
    }

    .grapple-letters-list {
        background: #F9F9F9;
        background-image: unset;
        
        .grapple-letters-list-container {
            background: unset;
        }

        .letter-item {
            border: 1px solid #CDCBC7;
        }
    }
}

.choose-letter {
    background: #CEE6F9;

    .choose-letter-container {
        max-width: 1200px;
        margin: auto;
        padding: 30px 1rem 0;

        h3 {
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 145.455% */
            margin-top: 0;
            margin-bottom: 8px;
        }
    
        & > p {
            font-family: Lora;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
            margin: 0;
        }
    }


    .grapple-letters-list {
        background: unset;
        background-image: unset;
        
        .grapple-letters-list-container {
            background: unset;
            padding-top: 43px;
        }

        .letter-item {
            border: 1px solid #CDCBC7;
        }
    }
}