.letter-content-container {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 32px 32px 16px;

    h5 {
        font-family: Lora;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.2px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 21px !important;
    }

    .letter-content {

        h4, h5, h6 {
            font-family: Lora;
            font-size: 12px;
            font-weight: 700;
            line-height: 22px; /* 183.333% */
            margin-bottom: 6px;
            margin-top: 20px;
        }

        h1, h2, h3 {
            font-family: Lora;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px; /* 183.333% */
            margin-bottom: 6px;
            margin-top: 20px;
        }

        p, ul, li, a {
            font-family: Lora;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px; /* 183.333% */
        }

        b {
            font-weight: 700;
        }
    }
}