.footer {
    background-color: #282828;
    color: #fff;
    width: 100%;
    padding: 28px 1rem 2.5rem;
    font-weight: 500;

    .footer-top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.50);
        margin-bottom: 1rem;

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin: 0;
            margin-bottom: 40px;
            text-align: center;
        }

        .footer-menu {
            display: flex;
            gap: 55px;
            width: 100%;
            justify-content: space-evenly;

            @media (max-width: 991px) {
                text-align: left;
                justify-content: center;
                gap: 100px;
            } 

            a {
                color: #ffffff;
            }

            .footer-icons {
                @media (max-width: 991px) {
                    display: none;
                } 
            }

            .footer-menu-2 {
                .footer-icons {
                    display: none;

                    @media (max-width: 991px) {
                        display: block;
                        margin-top: 36px;

                        ul {
                            display: flex;
                        }
                    } 
                }
            }

            p {
                margin-top: 0;
                margin-bottom: 12px;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 0.2px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                gap: 32px;

                @media (max-width: 991px) {
                    display: block;

                    li {
                        margin-bottom: 24px;
                    }
                } 

                li {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px; /* 171.429% */
                    letter-spacing: 0.2px;

                    .pi {
                        font-size: 21px;
                    }
                }
            }
        }
    }

    .footer-bottom {
        text-align: center;

        p {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }


    @media (min-width: 64rem) {
        padding: 2.5rem 5.125rem;
    } 
}
