.subscription-cancelled {
    background: #CEE6F9;

    & > div {

        text-align: center;
        max-width: 382px;
        margin: auto;
        padding-top: 55px;
        padding-bottom: 150px;
        padding-left: 16px;
        padding-right: 16px;

        h2 {
            margin-bottom: 16px;
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            margin-top: 32px;
        }

        p {
            font-family: Lora;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            letter-spacing: 0.2px;
        }

        .cancel-button {
            border-radius: 60px;
            background: #3592FE;
            padding: 14px 16px;
            color: #FFF;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
            margin-top: 50px;
            cursor: pointer;
        }
    }
} 