.grapple-full-access {

    .grapple-full-access-container {
        display: flex;
        gap: 20%;
        max-width: 1200px;
        margin: auto;
        padding: 0 1rem 40px 1rem;
        align-items: center;
    
        @media (max-width: 991px) {
           flex-direction: column;
           padding: 0 1rem 40px 1rem;
           gap: 20px;
    
           & > div:first-child img {
            width: 100%;
           }
        }
    
        & > div {
            text-align: center;
    
            h2 {
                color: #292929;
                font-size: 28px;
                font-weight: 500;
                line-height: 48px;
                max-width: 360px;
                margin: auto;
                margin-bottom: 2rem;
            }
    
            p {
                color: #292929;
                font-family: Lora;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px;
                max-width: 310px;
                margin: auto;
                margin-top: 20px;
                margin-bottom: 28px;
            }
    
            .read-more {
                border-radius: 60px;
                background: #3592FE;
                color: #FFF;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.2px;
                width: 350px;
                display: block;
                padding: 18px 16px;
    
                @media (max-width: 991px) {
                    width: 100%;
                 }
            }
        }
    }
}