.auth-popup {
    position: absolute;
    border-radius: 300px 300px 0px 0px;
    opacity: 0.96;
    background: var(--White, #FFF);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;

    @media (max-width: 991px) {
        border-radius: 50px 50px 0px 0px;
    }

    &.signup {
        .auth-popup-container {
            h2 {
                font-size: 32px;
                margin-bottom: 16px;
                @media (max-width: 991px) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }
            .action-type { 
                p {
                    color: #333;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    cursor: pointer;
                }
            }
        }
    }

    &.premium {
        .auth-popup-inner {
            padding-top: 0;
            max-width: 100%;
            border-radius: 300px 300px 0px 0px;
            padding: 0;
            overflow: hidden;

            @media (max-width: 991px) {
                border-radius: 50px 50px 0px 0px;
            }
            
            .header {
                background: #ECB344;
                padding: 24px 0;
                text-align: center;
                position: relative;

                @media (max-width: 991px) {
                    padding: 37px 16px 17px;
                }

                h2 {
                    font-size: 37px;
                    margin-bottom: 16px;
                    line-height: 47.1px;
                    max-width: 550px;
                    margin: auto;

                    @media (max-width: 991px) {
                        font-size: 26px;
                        line-height: 35px;
                    }
                }

                img {
                    position: absolute;
                    right: 150px;
                    top: 60px;
                    cursor: pointer;

                    @media (max-width: 991px) {
                        right: 14px;
                        top: 14px;
                    }
                }
            }

        }

        #payment-element {
            margin-bottom: 24px;
            padding: 16px 12px 12px 16px;
            border: 1px solid #e6e6e6;
            border-radius: 5px;
            background: #F9F9F9;
        }

        .auth-popup-container {
            padding-top: 40px;

            .premium-plan {
                border-color: #ECB344;
            }

            button {
                background: #ECB344;
            }

            .action-type { 
                margin-top: 22px;
                p {
                    color: #333;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    cursor: pointer;
                }
            }
        }
    }

    .auth-popup-container {
        text-align: center;
        max-width: 550px;
        margin: auto;
        padding-top: 32px;
        padding-bottom: 72px;
        padding-left: 18px;
        padding-right: 18px;

        @media (max-width: 991px) {
            padding-top: 55px;
        }

        h2 {
            font-size: 37px;
            font-weight: 500;
            line-height: 47.1px;
            margin-top: 0;
            margin-bottom: 24px;

            @media (max-width: 991px) {
                font-size: 25px;
                line-height: 32px; /* 128% */
            }
        }

        p {
            font-family: Lora;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
            margin-top: 0;
            margin-bottom: 30px;
        }

        .premium-plan {
            border-radius: 9px;
            border: 1px solid #2C3B8F;
            background: #FFF;
            max-width: 254px;
            margin: 0 auto 40px;
            padding: 16px 24px 4px;
            border-bottom: 12px solid #2C3B8F;
            position: relative;
    
            h3 {
                color: #292929;
                text-align: center;
                font-family: Montserrat;
                font-size: 31px;
                font-weight: 700;
                line-height: 62px;
                margin: 0;
            }
    
            p {
                color: #292929;
                text-align: center;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.2px;
                margin: 0;
            }

            .premium-plan {
                border-radius: 9px;
                border: 1px solid #2C3B8F;
                background: #FFF;
                max-width: 254px;
                margin: 0 auto 40px;
                padding: 16px 24px 4px;
                border-bottom: 12px solid #2C3B8F;
                position: relative;
                margin-bottom: 32px;

                > div {
                    position: absolute;
                    top: -10px;
                    border-radius: 8px;
                    background: var(--Dark, #2C3B8F);
                    padding: 0 8px;
                    left: 0;
                    right: 0;
                    width: max-content;
                    margin: auto;
                    color: #fff;
                    display: flex;
                    gap: 4px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.2px;                
                }
            }
        }

        .back-button {
            border-radius: 60px;
            border: 1px solid #3592FE;
            padding: 11px 16px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
            margin-bottom: 16px;
            cursor: pointer;
        }

        .footer-section {
            p {
                font-family: Lora;
                font-size: 12px;
                font-weight: 500;
                line-height: 22px; /* 183.333% */
                margin-top: 0;
                margin-bottom: 22px;
            }

            a {
                font-family: Lora;
                font-size: 12px;
                font-weight: 500;
                line-height: 22px; /* 183.333% */
                text-decoration-line: underline;
            }
        }

        .popup-checkout {

            img {
                display: block;
                margin: auto;
                margin-bottom: 1rem;
            }

            a {
                margin-top: 0;
                margin-bottom: 32px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.2px;
            }

            button {
                width: 100%;
                text-decoration: unset;
                margin: 0 auto 16px;
                color: #000204;
                padding: 12px 18px;
                border-radius: 20px;
                background: #ECB344;
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.20);
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.2px;
                border: unset;
                cursor: pointer;
            }

            #payment-element {
                padding: 0.75rem;
                border: 1px solid #e6e6e6;
                border-radius: 5px;
                max-width: 445px;
                margin: 0 auto 16px;
            }
        }

        .password-field {
            position: relative;

            img {
                top: 14px;
            }
        }

        input {
            color: #909090;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
            border-radius: 8px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
            border: 0;
            height: 48px;
            padding: 12px 12px 12px 16px;
            width: 100%;
            margin-bottom: 1rem;

            &[type="checkbox"] {
                border: 0;
                padding: 0;
                box-shadow: none;
                width: 24px;
                height: 24px;
                background: #f9f9f9;
                margin: 0;
            }
        }

        .checkbox-group {
            text-align: left;
            margin-top: 10px;

            label {
                display: flex;
                margin-bottom: 1rem;
                gap: 8px;
                align-items: center;

                a {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

        .error {
            font-family: Lora;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            color: red;
            text-align: left;
        }

        button {
            width: 100%;
            margin-top: 1rem;
            border-radius: 60px;
            background: #3592FE;
            padding: 14px 16px;
            border: 0;
            color: #FFF;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
            margin-bottom: 24px;

            &:disabled {
                opacity: 0.5;
            }
        }

        .close-button {
            text-align: center;
            font-family: Lora;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            text-decoration-line: underline;
            margin-top: 70px;
            margin-bottom: 38px;
        }
    }
}