.loader-section {
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    img {
        height: 240px;
        margin: auto;
    }
}

.lawyer-access {
    position: relative;
}