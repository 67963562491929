.screen {

    @media (min-width: 768px) {
        padding: 0 8rem 2rem 8rem;
    }

	min-height: calc(100vh - 158px);
	display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
	padding-top: 0;
    padding-bottom: 1.5rem;
	margin-top: 3rem;
	justify-content: space-between;

	.footer-section {
		margin: 4rem 0 0 0;

		img {
			margin-left: auto;
			margin-right: -10px;
		}
	}
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1280px) {
        max-width: 1366px;
    }
}

img {
    max-width: 100%;
}

.helpsection {
    max-width: 1200px;
    margin: auto;
    padding: 0px 1rem 50px;

    .breadcrumb {
        padding-bottom: 30px;
        padding-left: 0 !important;

        li {
            display: flex;
        }
    }
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}