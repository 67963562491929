.dashboard {

    h4 {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;

        @media screen and (max-width: 991px) {
            padding-left: 1rem;
        }
    }

    .p-datatable .p-datatable-thead > tr > th {
        padding: 0;
        background: unset;
        color: #000;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    .p-datatable .p-datatable-thead > tr > th:first-child {

        @media screen and (max-width: 991px) {
            padding-left: 1rem;
        }
    }

    .p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage > td {
        text-align: center;
    }

    .p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage {
        border: 1px dashed #FFF;
        background: rgba(255, 255, 255, 0.40);
    }

    .p-datatable .p-datatable-tbody > tr {
        border-radius: 4px;
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-top: 10px;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding-left: 0;
        padding-right: 0;
        padding-top: 8px;
        padding-bottom: 11px;
        border: 0;
    }

    .p-datatable .p-datatable-tbody > tr > td:first-child {
        padding-left: 12px;
    }

    .p-datatable .p-datatable-tbody > tr > td:last-child {
        padding-right: 12px;
    }

    .p-datatable-table {
        border-spacing: 0px 4px;
    }

    .letter-icon-text {
        display: flex;
        gap: 4px;
        cursor: pointer;

        img {
            max-width: 18px;
        }
    }

    .highlight-text {
        border-radius: 4px;
        background: #CEE6F9;
        width: max-content;
        padding: 2px 4px;
    }

    .letters .highlight-text {
        background: #00B066;
        padding: 2px 12px;
        color: #fff;

        @media screen and (max-width: 991px) {
            max-width: 125px;
        }
    }

    .action {
        display: flex;
        gap: 4px;
        width: 100%;

        .generate-icon {
            margin-left: 40px;
        }
    }

    .letters {
        margin-bottom: 54px;

        .letter-name {
            @media screen and (max-width: 991px) {
                width: 55%;
            }
        }
    }

    .otherletters {
        margin-bottom: 20px;
    }

    .popup {
        border-radius: 2px;
        background: #FFF;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
        padding: 5px 8px;
        font-size: 14px;

        .options {
            cursor: pointer;
        }

        div {
            display: flex;
            gap: 4px;
            // cursor: pointer;
        }

        & > div {
            border-bottom: 1px solid rgba(0, 0, 0, 0.30);
            padding-top: 8px;
            padding-bottom: 8px;
        }

        & > div:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    .dashboard-cta {
        border-radius: 4px;
        border: 1px solid var(--White, #FFF);
        background: rgba(255, 255, 255, 0.40);
        max-width: 425px;
        padding: 16px;
        margin-bottom: 10px;

        h4 {
            font-family: Lora;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin: 0;
            padding: 0;
        }

        p {
            margin-top: 8px;
            margin-bottom: 18px;
            color: #000;
            font-family: Lora;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
        }

        .button {
            border-radius: 8px;
            border: 1px solid #54A9F6;
            background:  #3576D9;
            padding: 8px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
            width: max-content;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
        }
    }
}

.hide-on-desktop {
    display: none;
}

@media screen and (max-width: 991px) {
    .hide-on-mobile {
      display: none!important;
    }
    .hide-on-desktop {
        display: block;
    }
}

.faqs-page {

    > h2 {
        @media screen and (max-width: 991px) {
            padding-left: 1rem;
        }
    }
}