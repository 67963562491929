.user-flow {
    background-color: #CEE6F9;
    padding-top: 40px;
    position: relative;

    @media (max-width: 991px) {
        min-height: 100vh;
    }

    :focus-visible {
        outline: 0;
    }

    .sv-page > .sv-row > .sv-row > div > .sv-question > .sv-question__header > .sv-title {
        color: red !important;
    }

    .sv-item__control:focus+.sv-item__decorator {
        border: 0;
    }

    .sv-container-modern {
        margin-bottom: 0;
    }

    :focus {
        outline: unset;
    }

    .sv-panel {
        margin-top: 0px;
        margin-bottom: 40px;

        .sv-panel__content {
            background: #dfeefb;
            padding: 10px 10px 0;
            margin-top: 0;
            font-style: italic;
        }

        .sv-title {
            font-size: 18px;

            &.sv-panel__title--expanded {
                background: #dfeefb;
                margin-bottom: 0;
                padding: 0 10px 0;
                display: flex;
                align-items: center;

                &:after {
                    position: unset;
                }
            }

            &.sv-panel__title--collapsed {
                display: flex;
                align-items: center;
                background: #f0f3f8;
                width: max-content;
                padding: 5px 10px;
                line-height: 1;
                border-radius: 10px;

                &:after {
                    position: unset;
                }
            }
        }
        .sv-row {
            padding: 0;
        }
    }

    div[data-key="question10"] {
        h5 {
            display: none;
        }
    }

    .input-header {
        max-width: 680px;
        margin: 0 auto 30px;
        padding-left: 16px;
        padding-right: 16px;

        img {
            margin-top: 3px;
        }

        .letter-title {
            font-family: Lora;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
        }

        .premium-letter-header {
            border-radius: 8px;
            background: #E5CE00;
            padding: 12px 16px;
            display: flex;
            align-items: flex-start;
            gap: 10px;
        }

        p {
            margin: 0;
            font-size: 14px;
            font-style: normal;
            line-height: 24px;
            font-weight: 500;
            letter-spacing: 0.2px;

            &:first-child {
                font-weight: 600;
            }

            a {
                color: #000204;
                font-weight: 600;
                text-align: right;
                float: right;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .sv-page {
        margin-bottom: 150px;
    }

    .sv-row {
        position: relative;
        max-width: 680px;
        margin: auto;
        padding: 0 16px;
    }

    .sv-question__content {
        padding: 0;
        margin: 0;
    }

    .sv-question__title--answer,
    .sv-question__title--error {
        background-color: unset;
    }

    .sv-title {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        padding: 0;
        margin-bottom: 8px;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;

        img {
            margin-top: 5px;
        }
    }

    .sv-description {
        padding: 0;
        color: #000204;
        font-family: Lora;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-bottom: 32px;

        .sv-string-viewer ul li {
            margin-bottom: 8px;
        }

        .sv-string-viewer ul {
            padding: 0;
            margin-bottom: 0;
            margin-top: 8px;
            list-style: none;
        }
    }

    .sv-text:focus {
        border-color: unset;
        border: 0;
    }

    .sv-checkbox {
        margin-bottom: 18px;

        &:hover {
            .sv-checkbox__svg {
                background: #F2F2F2;

                use{
                    display: none;
                }
            }
        }

        &.sv-checkbox--checked {
            .sv-checkbox__svg {
                border: none;
                background-color: #3592FE;
                fill: white;

                use {
                    display: block;
                }
            }
        }

        .sv-checkbox__svg {
            border: 0;
            border-radius: 2px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
        }
    }

    .sv-radio {
        margin-bottom: 16px;
        &:hover {
            .sv-radio__svg {
                fill: #3592FE;
                border: 1px solid #3592FE;
            }
        }
    }

    .sv-radio__svg {
        fill: white;
        background: white;
        border: 0;
    }

    .sv-radio--checked .sv-radio__svg {
        border-color: #3592FE !important;
        fill: transparent !important;
        border: 8px solid #3592FE !important;
    }

    .sv-comment:focus {
        border-color: unset;
    }

    div[data-name="response_letter_type"] {
        margin-bottom: 8px;
        .sv-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
        }
    }

    div[data-name="case_summary"], 
    div[data-name="questions"],
    div[data-name="letter_to"], 
    div[data-name="letter_from"], 
    div[data-name="comment_response"],
    div[data-name="total_salary"],
    div[data-name="offer_amount"],
    div[data-name="timeline"],
    div[data-name="age"],
    div[data-name="years_employed"],
    div[data-name="company_name"],
    div[data-name="contact_name"],
    div[data-name="contact_email"],
    div[data-name="contact_phone"],
    div[data-name="fullname"] {
        background: #fff;
        padding: 12px 18px 30px;
        border-radius: 8px;
        margin-bottom: 16px;

        .sv-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
            margin-bottom: 0;
        }

        textarea {
            border-radius: 2px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
            padding: 10px;
            border: 0;
            min-height: 150px;
        } 

        input {
            border-radius: 2px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
            padding: 10px;
            border: 0;
            min-height: 40px;
        }

        .sv-remaining-character-counter {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
        }
    }  

    div[data-name="case_summary"] {
        margin-bottom: 2rem;
    }

    .sv-body > .sv-components-column {
        background-color: #fff;
        padding: 16px 0 20px;
        width: 100%;
        position: absolute;
        bottom: 0;
        
        @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
            position: fixed;
            bottom: 0;
        }

        .sv-action-bar.sv-footer.sv-body__footer {
            max-width: 650px;
            margin: 0 auto 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;

            @media (max-width: 991px) {
                flex-direction: column;
                .sv-action {
                    width: 100%;
                }
            }

            .back-btn {
                border-radius: 60px;
                border: 1px solid #3592FE;
                padding: 14px 16px;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.2px;
                width: 160px;
                cursor: pointer;
                background: unset;
                font-family: inherit;

                @media (max-width: 991px) {
                    display: block;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 24px;
                }
            }

            .sv-btn--navigation {
                border-radius: 60px;
                border: 1px solid #3592FE;
                background: white;
                color: #000;
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
                padding-top: 14px;
                padding-bottom: 14px;

                @media (max-width: 991px) {
                    width: 100%;
                    text-align: center;

                    &.sv-footer__prev-btn {
                        margin-bottom: 24px;
                    }
                }

                &.sv-footer__next-btn, 
                &.sv-footer__complete-btn{
                    background: #3592FE;
                    color: #fff;
                }

                &:disabled {
                    opacity: 0.7;
                    cursor: default;
                }
            }

            input {
                margin: 0;
            }

            &:after {
                display: none;
            }

            div#sv-nav-start, div#sv-nav-preview, div#sv-nav-complete {
                display: none;
            }
        }
    }

    .sv-question__erbox {
        border-radius: 4px;
        background: rgba(255, 17, 17, 0.30);
        color: #000;
        font-family: Lora;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        padding: 8px;
        margin-top: 16px;
    }

    div[data-name="deadline1"],
    div[data-name="deadline2"] {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    div[data-key="date0"], 
    div[data-key="time_limit-1"], 
    div[data-key="note0"],
    div[data-key="date10"],
    div[data-key="deadline1-1"],
    div[data-key="date20"],
    div[data-key="date30"],
    div[data-key="deadline2-1"],
    div[data-key="date1_10"],
    div[data-key="date1_20"],
    div[data-key="date_last0"],
    div[data-key="start_date0"]{
        .sv-question {
            padding-right: 0;
        }
        .sv-title {
            font-family: Lora;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
            margin-bottom: 8px;
        }

        .sv-description {
            font-family: Lora;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-top: 16px;
            margin-bottom: 0;
        }

        input {
            border-radius: 8px;
            background-color: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
            width: 220px;
            height: 48px;
            padding: 11px 16px;
            border: 0;
            margin-bottom: 10px;

            @media (max-width: 991px) {
                width: 100%;
                margin-bottom: 24px;
            }

            &.sv-text--error {
                border: 2px solid #F11;
            }

            &[type=date] {
                background-image: url('../../assets/img/calender.svg');
                background-size: auto;

                &:focus {
                    border: 2px solid #3576D9;
                    background-image: url('../../assets/img/Calendar-dark.svg');
                }
            }
        }
    }

    div[data-key="time_limit-1"] {
        .sv-question--answered {
            input {
                background-image: url('../../assets/img/Check.svg');
                border: 2px solid #00B066;
            }
        }
        input {
            background-image: url('../../assets/img/calender.svg');
            background-size: auto;
            background-repeat: no-repeat, repeat;
            background-position: right 0.61em top 50%, 0 0;

            &.sv-text--error {
                border: 2px solid #F11;
                background-image: url('../../assets/img/Cancel.svg');

                @media (min-width: 992px) {
                    margin-bottom: 50px;
                }
            }
        }
        @media (min-width: 992px) {
            .sv-question__erbox {
                position: absolute;
                left: 16px;
                bottom: -1rem;
                margin: 0;
                right: 17.6%;
            }
        }
    }
}

.questions-3, 
.questions-4, 
.questions-5, 
.questions-6,
.statements {
    .user-flow {
        .sv-selectbase {
            border-radius: 8px;
            background: #FFF;
            padding: 16px 16px 0;

            & > div {
                display: flex;
    
                > div {
                    min-width: unset;
                    max-width: 100%;
                    width: 100%;
                }
            }
        } 
    }
}

.tooltip {
    position: absolute;
    padding: 16px 28px 16px 16px;
    display: none;
    z-index: 100;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    border: 0;
    color: #000;
    font-family: Lora;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.2px;

    @media (max-width: 991px) {
        left: unset !important;
        right: 16px;
    }

    .close-icon {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 2px;
        font-size: 20px;
        font-weight: bold;
    }
}
  
.statements div#sv-nav-complete,
.page4 div#sv-nav-complete,
.page3 div#sv-nav-complete,
.respond-to-employer  div#sv-nav-complete,
.page3.cdf3 div#sv-nav-complete,
.narrative div#sv-nav-complete, 
.page2.questions div#sv-nav-complete, 
.additional_info div#sv-nav-complete{
    display: block !important;
}

.statements .case-details div#sv-nav-complete {
    display: none !important;
}

.respond-to-employer  div#sv-nav-prev, 
div[data-key="selected_letter0"],
div[data-key="form_type0"],
div[data-key="user_type0"], 
.sv-action--hidden,
div[data-key="time_limit0"] {
    display: none;
}

div[data-key="letter_type0"] {
    margin-bottom: 2rem;
}

body {
    .btn.back-btn {
       display: none !important;
    }
}

.time-limit-calculator,
.respond-to-employer {
    .sv-action-bar.sv-footer.sv-body__footer {
        display: flex;
        flex-direction: row-reverse;

        @media (max-width: 991px) {
            flex-direction: column-reverse !important;
        }

        .btn.back-btn {
            display: block !important;

            &.none {
                display: none !important;
            }
        }
    }
}

.sv-footer__complete-btn:disabled {
    opacity: 0.7;
}
    
.additional_info .case-details, 
.narrative .case-details {
    .sv-body > .sv-components-column { 
        .sv-action-bar.sv-footer.sv-body__footer {
            justify-content: end;
        }
    }
}

.page3,
.questions-2 {

    .sv-action-bar.sv-action-bar--default-size-mode.sv-footer.sv-body__footer.sv-clearfix {
        position: relative;
    
        div#sv-nav-next {
            position: absolute;
            right: 0;
        }
    }
}

div[data-key="generatedQuestions0"] {
    display: none;
}

.additional_info {
    .sv-row {
        margin-bottom: 1rem;

        h5 {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
        }
    }
}

.additional_questions {
    .user-flow {
        .sv-question {
            background: #fff;
            padding: 12px 18px 30px;
            border-radius: 8px;
            margin-bottom: 16px;
    
            .sv-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 0.2px;
                margin-bottom: 0;
            }
    
            textarea {
                border-radius: 2px;
                background: #F9F9F9;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
                padding: 10px;
                border: 0;
                min-height: 150px;
            } 
    
            .sv-remaining-character-counter {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.2px;
            }
        }  
    }
}

.quill .ql-editor p {
    margin-bottom: 10px;
}