.recommended-letters {

    h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-bottom: 8px !important;
    }
    
    .recommended-letter-item {
        background-color: #fff;
        padding: 14px 16px;
        border-radius: 8px;
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 4px;

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
        }

        p:first-child {
            font-weight: 600;
            margin-bottom: 4px;
        }
        
        img:first-child {
            width: 60px;
            height: 60px;
        }

        img:last-child {
            margin-left: auto;
        }
    }
}