.auth {
    display: flex;
    background: #cee6f9;

    @media (max-width: 991px) {
        flex-direction: column-reverse;
    }

    & > div {
        width: 50%;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    .auth-form {
        background: #fff;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
        padding: 153px 90px;
        text-align: center;

        @media (max-width: 991px) {
            border-radius: 40px 40px 19px 19px;
            padding: 40px 1rem 109px;
            margin-top: -1rem;
        }
        
        h2 {
            margin-top: 0;
            margin-bottom: 24px;
        }

        h5 {
            margin-top: 0;
            margin-bottom: 32px;
        }

        .form-inner {
            max-width: 480px;
            margin: auto;
        }

        input {
            color: #909090;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
            border-radius: 8px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
            border: 0;
            height: 48px;
            padding: 12px 12px 12px 16px;
            width: 100%;

            &[type="checkbox"] {
                border: 0;
                padding: 0;
                box-shadow: none;
                width: 24px;
                height: 24px;
                background: #f9f9f9;
                margin: 0;
            }
        }

        .checkbox-group {
            text-align: left;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                display: flex;
                gap: 8px;
                align-items: center;

                span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.2px;

                    a {
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
        }

        .password-field {
            position: relative;
            margin: auto;
            margin-top: 1rem;

            input {
                margin-bottom: 0;
            }
        
            img {
                position: absolute;
                right: 12px;
                top: 15px;
            }
        }

        button,
        .premium-button,
        button.premium-button {
            cursor: pointer;
            border-radius: 60px;
            background: #00B066;
            border: 0;
            color: #fff;
            width: 100%;
            padding: 18px;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin-bottom: 30px;
            margin-top: 40px;
        }

        p {
            margin: 0;
        }

        .error {
            font-family: Lora;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            color: red;
            text-align: left;
        }

        .action-type p {
            font-family: Lora;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            cursor: pointer;
            margin-top: 1rem;
        }

        .link-text {
            margin-top: 22px;
            margin-bottom: 0;
        }
    }

    .premium-plan {
        border-radius: 9px;
        border: 1px solid #ECB344;
        background: #FFF;
        max-width: 254px;
        margin: 0 auto 40px;
        padding: 16px 24px 4px;
        border-bottom: 12px solid #ECB344;

        h3 {
            color: #292929;
            text-align: center;
            font-family: Montserrat;
            font-size: 31px;
            font-weight: 700;
            line-height: 62px;
            margin: 0;
        }

        p {
            color: #292929;
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin: 0;
        }
    }

    &.signup {
        .auth-image {
            min-height: 200px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            
            @media (max-width: 991px) {
                background-position: center 25%;
            }
        }
        .auth-form  {
            @media (max-width: 991px) {
                margin-top: -2rem;
            }
            .signup-buttoms {
                .premium-button {
                    color: #000;
                    background: #ECB344;

                    &:disabled {
                      opacity: 0.5;
                    }
                }
                a.continue-button  {
                    background: unset;
                    border: 2px solid #3592FE;
                    color: #3592FE;
                }
            }
        }

        .auth-form {

            h5 {
                margin-top: 0;
                margin-bottom: 1rem;
            }

            form {
                margin-top: 24px;

                & > div {
                    margin-bottom: 1rem;
                }
            }

            .signup-buttoms {
                display: flex;
                gap: 21px;
                margin-bottom: 0;

                @media (max-width: 991px) {
                    flex-direction: column;
                }

                button {
                    margin-top: 14px;
                    background: #3592FE;
                    @media (max-width: 991px) {
                        margin-bottom: 0;
                    }
                }

                a {
                    margin-top: 30px;
                    color: #000;
                    background: #ECB344;
                    @media (max-width: 991px) {
                        margin-top: 0;
                    }
                }
            }

            p {
                font-family: 'Lora';
                margin-bottom: 28px;

                &:last-child {
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .auth-image {
        min-height: 200px;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        
        @media (max-width: 991px) {
            background-position: center 25%;
        }
    }

    .payment-field {
        margin-bottom: 32px !important;
    }

    #payment-element {
        margin: 16px 0 0;
        padding: 0.75rem;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
    }
}

.reset-password { 
    padding-top: 100px;
    padding-bottom: 100px;
    background: #cee6f9;

    .forgotPassword {
        max-width: 360px;
        margin: auto;

        @media (max-width: 991px) {
            max-width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        h4 {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            margin-top: 0;
            margin-bottom: 16px;
        }

        p {
            font-family: Lora;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.2px;
            margin-top: 0;
            margin-bottom: 30px;
        }

        .password-field {
            margin-bottom: 12px;
            position: relative;

            img {
                top: 14px;
            }
        }

        input {
            border-radius: 8px;
            padding: 14px 12px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
            border: 0;
            width: 100%;
            font-size: 14px;
        }

        .error {
            font-family: Lora;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            color: red;
            text-align: left;
        }

        button {
            width: 100%;
            margin-top: 60px;
            border-radius: 60px;
            background: #3592FE;
            padding: 14px 16px;
            border: 0;
            color: #FFF;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
        }
    }
}
