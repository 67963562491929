.founder {
    background: #F9F9F9;

    .founder-container {
        align-items: center;
        display: flex;
        max-width: 1200px;
        margin: auto;
        padding: 65px 1rem;
        gap: 55px;
    
        @media (max-width: 991px) {
            flex-direction: column;
            padding: 40px 1rem 60px;
            gap: 45px;
            margin-bottom: -50px;
        }
    
        > div {
            max-width: 60%;
            width: 100%;
    
            @media (max-width: 991px) {
                max-width: 100%;
            }
        
    
            h4 {
                font-family: Lora;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
                margin: 0;
                margin-bottom: 16px;
            }
    
            p {
                font-family: Lora;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.2px;
                margin: 0;
                margin-bottom: 18px;
            }
    
            .author {
                display: flex;
                align-items: center;
                gap: 8px;
    
                @media (max-width: 991px) {
                    margin-top: 42px;
                }
    
                p {
                    margin: 0;
                    font-family: Lora;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                }
            }
    
            &:first-child {
                max-width: 40%;
    
                @media (max-width: 991px) {
                    max-width: 100%;
                }
            }
        }
    }
}