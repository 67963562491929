.letter {
    background-color: #CEE6F9;
    padding-top: 40px;
    padding-bottom: 100px;

    h5 {
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        margin-bottom: 10px;
    }

    .letter-header {
        display: flex;
        justify-content: space-between;
        max-width: 1012px;
        padding: 0 16px;
        margin: 0 auto 8px;


        .back-button {
            display: flex;
            gap: 5px;
            align-items: center;
            margin-bottom: 12px;
            font-size: 18px;
        }

        .letter-title {
            font-family: Lora;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 0;
        }

        .letter-status {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    .letter-container {
        max-width: 750px;
        margin: auto;
        padding: 0 16px;

       .additional-fields {
        margin-top: 20px;
       }

        .letter-body {
            display: flex;
            gap: 16px;

            @media (max-width: 991px) {
                flex-direction: column;
            }

            .letter-content-container {
                max-width: 100%;
                width: 100%;
                height: max-content;
                min-height: 700px;
                margin-bottom: 30px;

                @media (max-width: 991px) {
                    max-width: 100%;
                }

                .letter-content {
                    max-height: 720px;
                    overflow: auto;
                    user-select: none;

                    .highlight {
                        background-color: yellow;
                        font-weight: bold;
                    }

                    &.guest {
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            pointer-events: none;
                            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 90%);
                        }
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }
        
                    &::-webkit-scrollbar {
                        width: 4px;
                        background-color: transparent;
                    }
        
                    &::-webkit-scrollbar-thumb {
                        background-color: #D6D6D6;
                    }
                }

                .letter-actions {
                    border-top: 1px solid #D9D9D9;
                    padding-top: 20px;
                    margin-top: 20px;
                    padding-bottom: 10px;
                    display: flex;
                    justify-content: flex-end;
                    gap: 30px;

                    @media (max-width: 991px) {
                        flex-direction: column;
                        text-align: center;
                        gap: 1rem;

                        > div {
                            width: 100%;
                            justify-content: center;
                        }
                    }

                    .send-email, .check-letter {
                        display: flex;
                        gap: 8px;
                        border-radius: 60px;
                        border: 1px solid #fff;
                        background: #2C3B8F;
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.2px;
                        padding: 8px 16px;
                        cursor: pointer;

                        &.edit {
                            background: #3989F6;
                        }

                        &.approve {
                            background: #34C759;
                        }
                    }
                }
            }

            .letter-sidebar {
                max-width: 370px;
                width: 100%;

                @media (max-width: 991px) {
                    max-width: 100%;
                }

                .other-letters {
                    margin-top: 50px;
                }

                h5 {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.2px;
                    margin-bottom: 16px;
                }

                .video-responsive {
                    background: #fff;
                    padding: 16px;
                    border-radius: 8px;
                    margin-bottom: 10px;
                }

                .grapple-letters-list {
                    .grapple-letters-list-container {
                        padding: 0;
                        margin: 0;

                        & > div {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;

                            .letter-header {
                                padding: 0;
                                margin: 0;
                            }

                            h5 {
                                margin: 0;
                            }
                        }
                    }
                }


                .save-button {
                    padding: 18px 16px;
                    border-radius: 60px;
                    background: #3592FE;
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.2px;
                    text-align: center;
                    margin-top: 20px;
                    cursor: pointer;

                    @media (max-width: 767px) {
                        position: fixed;
                        bottom: 100px;
                        left: 16px;
                        right: 16px;
                    }
                }
            }
        }
    }

    &.client-review {
        padding-top: 0;

        .error-message {
            color: red;
            font-size: 14px;
            font-style: italic;
        }

        .letter-content {
            max-width: 800px;
            margin: auto;
        }

        &.internal {
            .comment {
                margin-bottom: 80px;
                label {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .letter-content-header {
                display: flex;
                justify-content: space-between;

                @media (max-width: 991px) {
                    flex-direction: column;
                }

                .letter-actions {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 14px;
                    align-items: center;

                    button {
                        border-radius: 60px;
                        min-width: 122px;
                        height: 36px;
                        border: 0;
                        color: #fff;
                        background: #5A5D63;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        text-align: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 600;
                        padding-left: 22px;
                        padding-right: 22px;

                        &.accept {
                            background: #34C759;

                            &:disabled {
                                background: #B0B0B0;
                            }
                        }
                    }
                }
            }

            h5 {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 1rem;
            }

            .error-message {
                color: red;
                font-style: italic;
                font-size: 12px;
            }

            select#letterSupervisor {
                color: #454545;
                font-family: Montserrat;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.2px;
                border: 0;
                background: no-repeat;
                font-weight: 600;

                &:focus, &:focus-within, &:focus-visible {
                    outline: 0px;
                    border: 0px;
                }
            }
        }

        .comment {
            label {
                color: #000;
                font-size: 15px;
                font-weight: 600;
            }

            textarea {
                background: #ffff;
                border: 0;
                min-height: 300px;
                width: 100%;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
                margin-top: 8px;
                margin-bottom: 8px;
                padding: 10px;
                font-family: inherit;
                line-height: 1.5;
                font-size: 14px;

                &:focus, 
                &:focus-within, 
                &:focus-visible {
                    outline: 0;
                }

                &:read-only {
                    background: #F9F9F9;
                }
            }

            button {
                border-radius: 60px;
                background: #3989F6;
                border: 0;
                font-size: 14px;
                font-weight: 600;
                min-width: 160px;
                height: 33px;
                color: #fff;
                margin-left: auto;
                float: right;
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 0 16px;

                &:disabled {
                    background: #ACACAC;
                }
            }

            &.toClient {
                margin-top: 20px;

                span {
                    float: left;
                }

                .action {
                    display: flex;
                    justify-content: end;
                    gap: 20px;
                    align-items: center;

                    .cancel {
                        border-radius: 60px;
                        min-width: 122px;
                        height: 36px;
                        border: 0;
                        color: #fff;
                        background: #5A5D63;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        text-align: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 600;
                        padding-left: 22px;
                        padding-right: 22px;
                        cursor: pointer;
                    }

                    .sendToClient {
                        float: unset;
                        margin: unset;
                    }
                }
            }
        }

        .letter-header-message {
            background: #102A43;
            padding-top: 38px;
            padding-bottom: 38px;
            color: #fff;
            margin-bottom: 50px;

            .letter-header {
                display: block;
                font-size: 16px;
                font-weight: 500;

                p {
                    font-size: 22px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.p-dialog .p-dialog-footer button {
    margin: 0 !important;
    color: #fff;
}

.progress-bar-container {
    max-width: 1012px;
    margin: auto;
    padding: 0 16px;
    margin-bottom: 26px;

    .progress-bar {
        background: #00B066;
        display: flex;
        justify-content: space-between;
        padding: 0 8px 0 4px;

        p {
            font-family: Lora;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 183.333% */
        }
    }
}

.submit-loader {

    &::after {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.50);
        position: absolute;
    }

    > div {
        display: flex;
        text-align: center;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 999;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
            animation: rotate 2s linear infinite;
        }
          
          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
        }
    
        p {
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.5px;
        }
    }
}

.comments-chat {
    margin-top: 20px;
    margin-bottom: 50px;
  
    h6 {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    .comments-list {
      display: flex;
      flex-direction: column;
      gap: 18px;
  
      .comment-item {
        padding: 10px;
        border-radius: 8px;
        font-size: 0.9em;
        width: 100%;
        text-align: left;
  
        &.left-align {
          align-self: flex-start;
          background-color: #e0f7fa;
        }
  
        &.right-align {
          align-self: flex-end;
          background-color: #ffffff;
        }
  
        .comment-type {
          font-weight: bold;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
        }
  
        .comment-text {
          margin: 5px 0;
        }
  
        .comment-timestamp {
          font-size: 0.8em;
          color: #777;
          text-align: right;
          float: right;
        }
      }
    }
}
  