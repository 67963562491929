h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 0;
}

h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;

    @media (max-width: 991px) {
        font-size: 18px;
        line-height: 20px; /* 150% */
    }
}

h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}

h5 {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 145.455% */

    @media (max-width: 991px) {
        font-size: 18px;
        line-height: 32px; /* 145.455% */
    }
}

a {
    text-decoration: none;
    color: #3592FE;
}

ul {
    list-style: square inside none;
    padding: 1rem;
}