.ccl-sign {
    margin-top: 84px;

    @media screen and (max-width: 991px) {
        margin-top: 40px;
    }

    &.container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    h2 {
        font-size: 36px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 14px;
        color: #454545;
        line-height: 1;

        @media screen and (max-width: 991px) {
            font-size: 24px;
        }
    }

    p {
        font-size: 21px;
        color: #454545;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 16px;

        @media screen and (max-width: 991px) {
            font-size: 17px;
        }
    }

    a {
        font-weight: 700;
        color: blue;
    }

    .terms {
        max-height: 500px;
        overflow: auto;
        background: #fafafa;
        padding: 40px 60px;
        margin-bottom: 48px;
        margin-top: 53px;

        @media screen and (max-width: 991px) {
            padding: 20px;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D6D6D6;
        }

        h3 {
            color: #454545;
            font-size: 24px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 10px;
            line-height: 1;

            @media screen and (max-width: 991px) {
                font-size: 20px;
            }
        }

        p {
            font-size: 19px;
            margin-bottom: 2rem;
            color: #454545;

            @media screen and (max-width: 991px) {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }

        h4 {
            color: #454545;
            font-size: 19px;
            font-weight: 700;
            margin-bottom: 10px;
            margin-top: 32px;
            margin-bottom: 10px;
            line-height: 1;

            @media screen and (max-width: 991px) {
                font-size: 16px;
            }
        }
        ol {
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 24px;

            li {
                font-size: 19px;
                margin-bottom: 1rem;
                font-weight: 500;
                color: #454545;

                @media screen and (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }

        ul {
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 32px;

            li {
                font-size: 19px;
                margin-bottom: 4px;
                font-weight: 500;
                color: #454545;

                @media screen and (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }
    }

    .form-group {
        margin-bottom: 2rem;

        input[type="checkbox" i] {
            width: 18px;
            height: 18px;
            margin-bottom: 14px;
            margin-right: 10px;
        }

        label, p {
            font-size: 19px;
            margin-bottom: 11px;
            font-weight: 500;
            color: #454545;

            @media screen and (max-width: 991px) {
                font-size: 16px;
            }
        }
    }

    button {
        border-radius: 8px;
        border: 1px solid #54A9F6;
        background:  #3576D9;
        padding: 12px;
        color: #FFF;
        font-size: 21px;
        font-weight: 500;
        cursor: pointer;
        width: 100%;
        margin-bottom: 45px;
        margin-top: 20px;

        @media screen and (max-width: 991px) {
            margin-top: 0px;
            font-size: 19px;
        }

        &:hover{
            opacity: 0.9;
        }
    }
}

.text-danger {
    color: red;
    font-size: 14px;
    font-style: italic;

    &.center {
        text-align: center;
    }
}

.ccl-completed {
    margin-top: 100px;
    margin-bottom: 100px;

    @media screen and (max-width: 991px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &.container {
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        }

    h2 {
        font-size: 36px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 14px;
        color: #454545;
        line-height: 1;

        @media screen and (max-width: 991px) {
            font-size: 24px;
        }
    }

    p {
        font-size: 21px;
        color: #454545;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 24px;

        @media screen and (max-width: 991px) {
            font-size: 16px;
            margin-bottom: 16px;
        }
    }

    .next-section {
        margin-top: 70px;
        margin-bottom: 70px;

        @media screen and (max-width: 991px) {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        h3 {
            color: #454545;
            font-size: 21px;
            margin-bottom: 2rem;
            margin-top: 0;
            line-height: 1;

            @media screen and (max-width: 991px) {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }

        ul {
            color: #454545;
            font-size: 21px;
            margin-top: 0;
            padding-top: 0;

            @media screen and (max-width: 991px) {
                padding-bottom: 10px;
            }

            @media screen and (max-width: 991px) {
                font-size: 16px;
            }

            li {
                margin-bottom: 16px;
            }
        }
    }
}