body, 
.p-component,
.sv-body__page,
.sv-title,
a {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	color: #000204;
}

.App {
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 100vh; /* Make the App fill the full viewport height */
}
  
  
.header + div {
	flex-grow: 1;
}
  