.profile {

    @media (max-width: 991px) {
        h1 {
            padding: 0 16px;
        }
    }
    .profile-inner {
        background: #fff;
        padding: 28px 16px;


        h2 {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            margin-top: 0;
            margin-bottom: 16px;
        }

        .submit-button {
            border-radius: 60px;
            background: #3592FE;
            border: 0;
            padding: 18px 16px;
            color: #FFF;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
            max-width: 355px;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 50px;
            cursor: pointer;

            @media (max-width: 991px) {
                max-width: 100%;
            }
        }

        .personal-info {
            margin-bottom: 38px;

            .profile-field {
                margin-top: 0;
                margin-bottom: 16px;
                padding: 12px 241px 12px 16px;
                border-radius: 8px;
                background: #F9F9F9;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
                max-width: 355px;
                color: #333;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;

                @media (max-width: 991px) {
                    max-width: 100%;
                }
            }
        }

        .payment-details {
            margin-bottom: 55px;
        }

        .cancel-button {
            border-radius: 60px;
            border: 0;
            padding: 18px 16px;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
            width: 355px;
            margin-top: 20px;
            text-align: center;
            cursor: pointer;

            @media (max-width: 991px) {
                width: 100%;
            }
        }

        .payment-form {
            #payment-element {
                border-radius: 8px;
                background: #F9F9F9;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
                width: 355px;
                padding: 17.61px 24px 17.6px 12px;
                margin: 1rem 0 50px;
            }

            #payment-message {
                color: red;
                font-size: 14px;
                text-align: center;
                max-width: 355px;
            }

            #submit {
                border-radius: 60px;
                background: #3592FE;
                height: 53px;
                border: 0;
                color: #FFF;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
                max-width: 355px;
                width: 100%;
            }
        }

        .card-detail {
            display: flex;
            border-radius: 8px;
            background: #F9F9F9;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
            width: 355px;
            justify-content: space-between;
            padding: 14px 24px 14px 12px;

            @media (max-width: 991px) {
                width: 100%;
            }

            p {
                margin: 0;
                color: #333;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
            }

            & > div {
                display: flex;

                & > p {
                    margin-left: 20px;
                }
            }
        }

        .password {
            .password-field {
                max-width: 355px;
                margin-bottom: 16px;
                width: 100%;
                @media (max-width: 991px) {
                    max-width: 100%;
                }

                input {
                    border-radius: 8px;
                    background: #F9F9F9;
                    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
                    border: 0 !important;
                    padding: 12px 240px 12px 16px;
                    color: #909090;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    width: 100%;
                    outline: 0 !important;
                }

                .error {
                    color: red;
                    font-size: 12px;
                }

                img {
                    top: 15px;
                }
            }
        }

        @media (min-width: 992px) {
            width: calc(100vw - 182px);
            margin-left: -32px;
            background: #fff;
            padding: 28px 0 28px 110px;
            margin-top: 36px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
        }
    }
}