.letter-details {
    position: relative;

    &.premium {

        .banner {
            background: #ECB344;
        }
    }

    .premium-band {
        background: #ECB344;
        padding-top: 25px;
        padding-bottom: 10px;
        text-align: center;
        bottom: 50px;
        position: fixed;
        left: 0;
        right: 0;

        @media (max-width: 991px) {
            padding: 10px 16px 16px;
        }

        p {
            max-width: 450px;
            margin: auto;
            margin-bottom: 8px;
        }

        .get-started {
            max-width: 315px;
            width: 100%;
            padding: 18px 16px;
            border-radius: 60px;
            color: #FFF;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            background: #3592FE;
            margin: auto;
            margin-top: 16px;
            display: block;
            cursor: pointer;

            @media (max-width: 991px) {
                margin-top: 8px;
            }
        }
    }

    .banner {
        background: #CEE6F9;
        background-image: unset;
        min-height: unset;
        
        .banner-container {
            padding-top: 50px;
            padding-bottom: 80px;
            display: flex;
            justify-content: space-between;
            gap: 40px;
            align-items: center;
    
            @media (max-width: 991px) {
                padding-top: 40px;
                padding-bottom: 40px;
                flex-direction: column;
            }
    
            h1 {
                margin-bottom: 16px;
            }
        
            p {
                margin-bottom: 48px;
            }

            ul {
                margin-bottom: 24px;
            }

            .banner-caption {
                width: 50%;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            .video-responsive {
                width: 50%;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            .banner-image {
                align-self: end;
                margin-right: 80px;

                @media (max-width: 991px) {
                    align-self: unset;
                    margin-right: 0;
                    text-align: center;
                }

                img {
                    margin-bottom: -4px;
                }

                .premium-letter {
                    background-color: #000204;
                    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
                    padding: 8px;
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.2px;
                    text-align: center;

                    @media (max-width: 991px) {
                        max-width: 290px;
                        margin: 0 auto 40px;
                    }
                }
            }
        }

        .breadcrumb {
            max-width: 1200px;
            margin: auto;
            padding: 8px 1rem 0;
            gap: 0;
    
            @media (max-width: 991px) {
                padding: 8px 1rem 0;
            }
    
            li {
                color: rgba(0, 0, 0, 0.60);
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.2px;
                display: flex;
                gap: 6px;
                text-wrap: nowrap;

                &:last-child {
                    overflow: hidden;
                }
    
                a {
                    color: rgba(0, 0, 0, 0.40);
                    text-decoration: underline;
                }
            }
        }
    }

    .content {
        max-width: 1200px;
        margin: auto;
        padding: 30px 1rem 60px;
        display: flex;
        gap: 50px;

        @media (max-width: 991px) {
            flex-direction: column;
            gap: 32px;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }

        > div:first-child {
            @media (max-width: 991px) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .video-responsive {
            margin-bottom: 40px;
        }

        .desc {
            h4 {
                font-family: Lora;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.2px;
                margin: 0;
            }
    
            p {
                font-family: Lora;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                letter-spacing: 0.2px;
                margin-bottom: 48px;
            }

            a {
                text-decoration: underline;
                font-weight: 500;
            }
        }

        .table-content {
            font-family: Lora;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.2px;
            

            table {
                width: 100%;
                border-collapse: collapse;
                margin: 16px 0 0;
                font-family: Lora;
                font-size: 16px;
                border: 1px solid #D6D6D6;
                border-top: 0;
                

                th {
                    text-align: left;
                    font-weight: normal;
                    padding: 10px;
                    border-bottom: 1px solid #D6D6D6;
                    background-color: #D6D6D6;
                }
                
                td {
                    padding: 10px;
                    border-bottom: 1px solid #D6D6D6;
                }

                tr:first-child {
                    th {
                        font-weight: bold;
                    }
                }

                th:first-child {
                    width: 150px;
                }
            }
            
  
        }


        > div:first-child {
            max-width: 67%;
            width: 100%;

            @media (max-width: 991px) {
                max-width: 100%;
            }
        }

        .single-sidebar {
            max-width: 33%;
            width: 100%;

            @media (max-width: 991px) {
                max-width: 100%;
            }

            .ms-book-consultation-container {
                flex-direction: column;
                gap: 0;
                padding: 30px 22px 20px;
                text-align: center;

                @media (max-width: 991px) {
                    padding: 30px 20px;
                }

                img {
                    max-width: 160px;
                    margin-bottom: 40px;
                }

                a {
                    width: 100%;
                }
            }
        }
    }
}