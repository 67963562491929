.ms-book-consultation {
    background: #282828;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);

    .ms-book-consultation-container {
        max-width: 1200px;
        margin: auto;
        padding: 64px 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #fff;
    
        @media (max-width: 991px) {
            flex-direction: column;
            gap: 40px;
            padding: 30px 1rem;
    
            img {
                max-width: 160px;
            }
        }
    
        h4 {
            font-family: Montserrat;
            font-size: 22px;
            font-weight: 700;
            line-height: 32px;
            margin: 0;
        }
    
        p {
            max-width: 500px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
            margin-bottom: 30px;
            margin-top: 10px;
    
            @media (max-width: 991px) {
                margin-bottom: 28px;
            }
        }
    
        a {
            border-radius: 55px;
            background: #3592FE;
            color: #FFF;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            width: 295px;
            display: block;
            padding: 18px 16px;
            text-align: center;
    
            @media (max-width: 991px) {
                width: 100%;
            }
        }
    }
}
