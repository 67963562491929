
.p-dialog {
    min-width: 375px;

    .p-dialog-header {
        text-align: center;
        padding: 1.25rem !important;
        padding-bottom: 0 !important;
    }

    .p-dialog-footer {
        text-align: center !important;
    }

    .p-dialog-header-icons {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .p-dialog-content {
        padding: 0 1.25rem 1.25rem 1.25rem !important;
        margin-top: -1px;

        h4 {
            font-weight: 600;
            line-height: 1.235;
            font-size: 24px;
            color: #000;
            margin-top: 20px;
            margin-bottom: 0;
            text-align: center;
            margin-bottom: -15px;
        }

        p {
            margin-top: 20px;
            margin-bottom: 0;
            text-align: center;
        }

        input {
            margin-top: 20px;
        }

        form {
            margin-top: 16px;
            margin-bottom: 16px;

            .error.response {
                text-align: center;
                margin-bottom: -10px;
            }
        }

        input.textbox {
            width: 100%;
            padding: 17px 12px;
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            border: 0;
            border-bottom: 1px solid #b3b3b3;
            background: #f4f4f4;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        label {
            font-size: 11px;
            margin-left: 8px;
        }

        .action-type {
            p {
                font-weight: 500;
                
                span {
                    color: #68c170;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &.link-text {
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .error {
            font-size: 12px;
            letter-spacing: 0.4px;
            color: #f44336;
            margin-left: 2px;
        }

        button[type="submit"] {
            font-family: 'Montserrat', sans-serif;
            padding: 8px 22px;
            font-size: 0.9375rem;
            color: #fff;
            background-color: #68c170;
            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
            border: 0;
            min-width: 64px;
            font-weight: 500;
            line-height: 1.75;
            border-radius: 4px;
            display: flex;
            margin: auto;
            margin-top: 30px;
            cursor: pointer;

            &:hover {
                background-color: rgb(72, 135, 78);
            }

            &:disabled {
                color: rgba(0, 0, 0, 0.26);
                box-shadow: none;
                background-color: rgba(0, 0, 0, 0.12);
                cursor: unset;
            }
        }

    }
    
    .thank-you {
        text-align: center;

        button {
            margin-top: 20px;
            border: 0;
            color: #fff;
            padding: 8px 22px;
            font-size: 0.9375rem;
            background-color: #68c170;
            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgb(72, 135, 78);
            }
        }
    }

    button:focus,
    .p-dialog-header-icon:focus {
        box-shadow: unset !important;
    }
}


.password-field {
    position: relative;

    img {
        position: absolute;
        right: 12px;
        top: 38px;
    }
}